import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { RadioGroup, Radio, InputAdornment, IconButton, Typography, Button, Divider, Modal, Stack, TextField, Card, Avatar } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import { Dispatch, SetStateAction } from 'react';

import appleIcon from "./../../img/apple_icon.png"
import googleIcon from "./../../img/google_icon.png"
import lineIcon from "./../../img/line_icon.png"
import MoreButton from '../Buttons/more';
import { PostCodeByMail } from '../API/mail';
import { Authenticate } from '../API/code';
import { GetUsers, useGetUsers, useLogin, usePreSetLogin, usePutUser } from '../API/users';
import LimitedMultiOutlinedBordered from '../Textfield/limited_multiline_outlined_bordered';
import { useSearchParams } from "react-router-dom";
import { MyContext } from '../../routes/router';
// import PopupIdeaEditAndPreview from './ideaEditAndPreview';
import { propTypes } from 'react-wavify';
import { useIsMobile } from '../isMobile';
import PopupRegisterError from './snsRegisterError';
import MultiTextfieldsLimitedMultilineOutlinedBordered from '../Textfield/MultiTextFields/normal';
import { simple } from '../../interfaces/user';
import { UseQueryResult } from 'react-query';
import LimitedMultilineOutlinedBordered from '../Textfield/limited_multiline_outlined_bordered';
import LimitedCheckboxCategoriesNew from '../CheckBox/limitedCategories';
import { useIsSP } from '../isSP';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 450,
    overflowY: "auto",
    backgroundColor: '#F6F6F6',
    alignItems: "center",
    borderRadius:5
};


const styleSP = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    // height: "70%",
    padding: "40px 10px 40px 20px",
    overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "flex-start",
    alignItems: "stretch",
    borderRadius:5
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    handleOpenLogin?: ()=>void;
  }

const stepBar = (index:number, step:number, text:string, isMobile: boolean) => {
    return ( 
        <Stack alignItems={"center"} spacing={1}>
            <Typography sx={{color: index <= step ? "white" : index > step ? "lightgrey" : "white", fontSize:isMobile ? 12 : 16, padding:"3px 9px", fontWeight: 'bold', border: index <= step ? "2px solid #383E86" : "2px solid lightgrey", backgroundColor: index > step ? "white" : "#383E86", borderRadius:"40px", transition: 'border 0.4s ease, color 0.4s ease, background-color 0.4s ease'  }}>
                {index}
            </Typography>
            <Typography sx={{color: index <= step ? "#383E86" : "lightgrey", fontSize:isMobile ? 14 : 16, fontWeight: 'bold', transition: 'color 0.4s ease, background-color 0.4s ease'  }}>
                {text}
            </Typography>
        </Stack>
    )
}

const snsRegister = () => {
    return (
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"} spacing={2}>
            <Button onClick={() => {window.location.href=process.env.REACT_APP_OFFICIAL_API_URL+"/register/auth/google?originalURL="+window.location.href}} variant='contained' sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:14, fontWeight: 'bold',  }}>
                        <img style={{position:"absolute", left:10}} src={googleIcon} width={"30px"}/>Googleで登録
                    </Stack>
                </Typography>
            </Button>
            
            <Button onClick={() => {window.location.href=process.env.REACT_APP_OFFICIAL_API_URL+"/register/auth/line"}} variant='contained' sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:14, fontWeight: 'bold',  }}>
                        <img style={{position:"absolute", left:10}} src={lineIcon} width={"30px"}/>Lineで登録
                    </Stack>
                </Typography>
            </Button>
            <Button variant='contained' disabled sx={{boxShadow:"none", border:"0.5px solid lightgrey", width:250, fontSize:12, padding:"5px", borderRadius:"20px", color:"#2B2B2B", backgroundColor: "white", ":hover":{backgroundColor: "snow",}}}>  
                <Typography component="div" textAlign={"center"} width={"100%"} sx={{margin:"5px auto", letterSpacing: '0px', textTransform:"none"}}>
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{position:"relative", color: "#2B2B2B", fontSize:14, fontWeight: 'bold',  }}>
                        <img style={{position:"absolute", left:10}} src={appleIcon} width={"30px"}/>Appleで登録(準備中)
                    </Stack>
                </Typography>
            </Button>
        </Stack>
    )
}

const textForm = (isMobile:boolean, text:string, placeholder:string,  value:string, setValue:Dispatch<SetStateAction<string>>, errorCheck?:(text:string)=>boolean, error?:boolean, error_text?:string, max?:number, rows?:number, maxdisplay?:boolean) => {
    return(
        <Stack direction={"row"} justifyContent={"space-between"} width={isMobile ? "100%" : "80%"} spacing={error ? 0 : 1}>
            <Stack width={"40%"} justifyContent={"center"} alignItems={"flex-start"}>
                <Typography sx={{color: "grey", fontSize:isMobile ? 13 : 16, fontWeight: 'bold', }}>
                    {text}
                </Typography>
            </Stack>
            <Stack width={"100%"} justifyContent={"center"} alignItems={"flex-start"}>

                {!(text == "") && !error ?
                <LimitedMultiOutlinedBordered 
                    border={"0.2px solid lightgrey"} 
                    padding={"9px"}
                    errorCheck={errorCheck}
                    backgroundColor={"white"}
                    width='100%'
                    fontSize={isMobile ? "12px" : '14px'}
                    max={max}
                    maxdisplay={maxdisplay ? maxdisplay : false}
                    maxRows={rows}
                    placeholder={placeholder}
                    value={value}
                    setValue={setValue}/>
                :
                    <Typography ml={1} sx={{color: "red", fontSize:isMobile ? 10 : 12, fontWeight: 'medium', }}>
                        {error_text}
                    </Typography>
                }
            </Stack>
        </Stack>
    )
}

const categoryForm = (categories:(string | null)[], setCategories:Dispatch<SetStateAction<(string | null)[]>>,)=>{
    return(
        <Stack direction={"row"} justifyContent={"space-between"} width={"80%"} spacing={1}>
            <Stack width={"40%"} justifyContent={"center"} alignItems={"flex-start"}>
                <Typography sx={{color: "grey", fontSize:16, fontWeight: 'bold', }}>
                    業種
                </Typography>
            </Stack>
            <Stack width={"100%"} justifyContent={"center"} alignItems={"flex-start"}>
                <LimitedCheckboxCategoriesNew fontSize={14} max={3} defaultCategories={[]} categories={categories} disabled={false} setCategories={setCategories}/>
            </Stack>
        </Stack>
    )
}

const textForm2 = (text:string, placeholder:string,  value:string, setValue:Dispatch<SetStateAction<string>>, error_text?:string | null) => {
    return(
        <Stack width={"100%"} justifyContent={"flex-start"} spacing={1}>
            <Typography sx={{color: "grey", fontSize:12, fontWeight: 'bold', }}>
                {text}
            </Typography>
            <LimitedMultilineOutlinedBordered 
                border={"0.2px solid lightgrey"} 
                padding={"9px"}
                backgroundColor={"white"}
                fontSize='14px'
                placeholder={placeholder}
                value={value} 
                setValue={setValue}/>
            {error_text && <Typography width={"100%"} textAlign={"left"} color={"red"}>{error_text}</Typography>}
        </Stack>
    )
}


const testForm = (isMobile:boolean, text:string, placeholder:string,  value:string, setValue:Dispatch<SetStateAction<string>>, errorCheck?:(text:string)=>boolean, error?:boolean, error_text?:string, max?:number, rows?:number, maxdisplay?:boolean) => {
    return(
        <Stack justifyContent={"flex-start"} spacing={1}>
            <Typography sx={{color: "grey", fontSize:12, fontWeight: 'bold', }}>
                {text}
            </Typography>
            <MultiTextfieldsLimitedMultilineOutlinedBordered 
                border={"0.2px solid lightgrey"} 
                padding={"9px"}
                backgroundColor={"white"}
                fontSize='14px'
                placeholders={["000", "0000", "0000"]}
                value={value} 
                cells={2}
                interval={"-"}
                setValue={setValue}/>
                
        </Stack>
    )
}

function mailErrorCheck(email:string) {
    // メールアドレスの検証に使用する正規表現
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

const passErrorCheck = (text:string) => {
    const regex = /^(?=.*[a-z])(?=.*\d).{6,}$/;
    if (!regex.test(text)) {
      return true;
    }
    return false;
  };

const telErrorCheck = (text:string) => {
    const regex = /^\d{2,5}-\d{3,4}-\d{3,4}$/;
    if (!regex.test(text)) {
        return true;
    }
    return false;
};

const zipcodeErrorCheck = (text:string) => {
    const regex = /^\d{3}-\d{4}$/;
    if (!regex.test(text)) {
        return true;
    }
    return false;
};

const passDuplicateErrorCheck = (password:string, mailsAndPasswords:{mail:string, password:string}[] | null) => {
    const temp = mailsAndPasswords?.filter((value: { mail: string; password: string; }, index: number,) => value.password == password )
    if(temp){
        if(temp.length > 0) return true;
        else return false
    }else{
        return false;
    }};


const PopupRegisterForm: React.FC<Props> = ({ handleClose, open, setOpen, handleOpenLogin }) => {

    // ページの状態がロード状態であるかどうかのCon
    const [searchParams] = useSearchParams();
    // registerID という名前で仮登録したuser_idを発行
    const registerID = searchParams.get("registerID");
    const mail_query = searchParams.get("mail") ? decodeURIComponent(searchParams.get("mail") as string) : ''
    // URLからの強制展開
    const register = searchParams.get("register");
    const [showRegisterErrorPopup, setShowRegisterErrorPopup] = useState<boolean>(false);
    const putUserMutation = usePutUser();
    const getUserMutation = GetUsers();

    const mailsAndPasswords = null;
    const { data: provisional_user, isLoading: isLoading_user }: UseQueryResult<simple | null, Error> & { queryKey: string }  = useGetUsers("user_simple", "simple", registerID ?? "none");

    const [user_name, setUserName] = useState<string>("")
    const [type, setType] = useState(0);
    const [mail_error_message, setMailErrorMessage] = useState<string | null>(null)
    const [authenticate_error_message, setAuthenticateErrorMessage] = useState<string | null>(null)
    const [mail, setMail] = useState<string>("")
    const [pass, setPass] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const [step, setStep] = useState<number>(1)
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const [registerStatus, setRegisterStatus] = useState<number>(0);

    const socket = useContext(MyContext).socket
    const openIdeaPostPopup = useContext(MyContext).openIdeaPostPopup

    const [manager_name, setManagerName] = useState<string>("")
    const [zipcode, setZipcode] = useState<string>("")
    const [address, setAddress] = useState<string>("")
    const [categories, setCategories] = useState<(string | null)[]>([])
    const [description, setDescription] = useState<string>("")
    const [tel, setTel] = useState<string>("")
    const login_mutation = useLogin(()=>{setStep(4)}, ()=>{console.log("failed")}, mail, pass, socket.id);
    const presetLoginMutation = usePreSetLogin(()=>{setStep(4)}, ()=>{console.log("failed")}, socket.id);
                                                            

    useEffect(() => {
        if(register === "true") setOpen(true)
    }, []);

    useEffect(() => {
        // ユーザーが存在しているけど、仮登録ではない場合
        if(provisional_user?.user_id !== undefined && provisional_user?.provisional !== true){
            setRegisterStatus(100)
            setStep(3)
            setUserName(provisional_user.user_name)
            setMail(mail_query)
        }
        // 仮登録をした時のユーザー情報が見つからないか、SNSログインがそもそも失敗しているか
        else if((registerID !== null && (isLoading_user === false && provisional_user === null)) || registerID === ""){
            // 会員登録時エラーのポップアップを表示
            setShowRegisterErrorPopup(true)
            setOpen(false)
            setStep(1)
        // 仮登録しているユーザーが存在するならば登録フォームを再展開
        }else if(provisional_user?.provisional === true){
          // ステップ1、2の時に SNS認証フロー を通過しているのであればステップ3へ移動する。
            if(provisional_user && step <= 2){
                if(provisional_user.provisional === true){
                    setUserName(provisional_user.user_name)
                    setMail(mail_query);
                    setStep(3);
                    setOpen(true);
                }else{
                    console.log("100")
                    setRegisterStatus(100);
                    setUserName(provisional_user.user_name);
                    setMail(mail_query);
                    setStep(3);
                    setOpen(true);
                }
            }
        }
        
    }, [open, isLoading_user]);

  //モバイル端末かどうかを判定
  const isMobile = useIsSP();
  const styles = isMobile ? styleSP : style;

  return (
    <>
        <Modal open={open} onClose={handleClose} sx={{border:"none",}}>
            <Fade in={open}>
                <Stack sx={{...styles}} >
                    <div style={{ position: 'absolute', top: isMobile ? 2:10, right: isMobile ? 2:10 }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon  sx={{fontSize:24}} />
                        </IconButton>
                    </div>

                    <Stack width={isMobile ? "100%" : "80%"}  p={isMobile ? 0:2} mb={isMobile ? 0 : 6} >
                        {/* ステップバー */}
                        <Stack mt={isMobile ? 0 : 3} direction={"row"} justifyContent={"space-evenly"} width={"100%"} spacing={isMobile ? 1:3 }>
                            {stepBar(1, step, "登録", isMobile)}
                            {stepBar(2, step, "メール認証", isMobile)}
                            {stepBar(3, step, "情報を入力", isMobile)}
                            {stepBar(4, step, "完了", isMobile)}
                        </Stack>

                            {step == 1 &&
                                <Stack mt={isMobile ? 3 : 10} width={"100%"} spacing={isMobile ? 0 : 2} direction={isMobile ? "column" : "row"} >
                                    <Stack spacing={isMobile ? 4:4} mb={isMobile ? 4:0} width={"100%"}>
                                        <Stack width={"100%"}>
                                            {textForm2("メールアドレス", 'メールアドレスを入力', mail, setMail, mail_error_message)}
                                        </Stack>
                                        <Stack justifyContent={"flex-start"} spacing={1}>
                                            <MoreButton 
                                                onClick={async ()=>{
                                                    await getUserMutation.mutate({type: "simple", user_id:null, query: "mail="+mail+"&count=true"}, {onSuccess: (result:any)=>{
                                                        if(mailErrorCheck(mail) === false){
                                                            setMailErrorMessage("メールアドレスの形式が正しくありません。例.example@domain.com")
                                                        }else if(Number(result.count) > 0){
                                                            setMailErrorMessage("そのメールアドレスは既に登録されています。")
                                                        }else{
                                                            setMailErrorMessage(null);
                                                            setStep(2); PostCodeByMail(mail)
                                                        }
                                                    }})
                                                }} 
                                                width='100%' 
                                                fontSize='14px' 
                                                height="4.5em"
                                                borderRadius='10px'
                                                boxShadow="none"
                                                text='認証コードを送信'/>
                                        </Stack>
                                    </Stack>
                                    {!isMobile && <Divider orientation={isMobile ? "horizontal" : "vertical"} sx={{backgroundColor:"gainsbolo"}}/>}
                                    {snsRegister()}
                                </Stack>
                            }

                            {step == 2 &&
                                <Stack mt={4} spacing={2} alignItems={"center"} width={"100%"}>
                                    <Stack justifyContent={"flex-start"} width={isMobile ? "100%" : "80%"} spacing={0}>
                                        {textForm2("メールアドレス", 'メールアドレスを入力', mail, setMail, mail_error_message)}
                                    </Stack>

                                    <Typography sx={{ width:isMobile ? "100%" : "80%", color: "grey", fontSize:12, fontWeight: 'medium', }}>
                                        上記のメールアドレスに認証コードを送信しました。メールに記載されている6桁の認証コードを入力してください。
                                        ※認証コードの有効時間は1時間です。
                                    </Typography>

                                    <Stack justifyContent={"flex-start"} width={isMobile ? "100%" : "80%"} spacing={0.5}>
                                        <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                                            <Typography sx={{color: "grey", fontSize:12, fontWeight: 'bold', }}>
                                                認証コード
                                            </Typography>

                                            <Typography mt={0} onClick={()=>{PostCodeByMail(mail)}} sx={{ ":hover":{textDecoration:"underline", color: "#039be5",}, cursor:"pointer", color: "#29b6f6", fontSize:10, fontWeight: 'bold', }}>
                                                認証コードを再送信する
                                            </Typography>
                                        </Stack>
                                        <Stack width={"100%"}>
                                            <LimitedMultilineOutlinedBordered 
                                                border={"0.2px solid lightgrey"} 
                                                padding={"9px"}
                                                backgroundColor={"white"}
                                                fontSize='14px'
                                                placeholder='6桁の認証コードを入力' 
                                                value={code} 
                                                max={6}
                                                setValue={setCode}/>
                                            <Typography width={"100%"} textAlign={"left"} color={"red"}>{authenticate_error_message}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack justifyContent={"flex-start"} spacing={1}>
                                        <MoreButton 
                                            onClick={async()=>{
                                                // 既に登録されているメールアドレスかどうか
                                                await getUserMutation.mutate({type: "simple", user_id:null, query: "mail="+mail+"&count=true"}, {onSuccess: async (result:any)=>{
                                                    if(mailErrorCheck(mail) === false){
                                                        setMailErrorMessage("メールアドレスの形式が正しくありません。例.example@domain.com")
                                                    }else if(Number(result.count) > 0){
                                                        setMailErrorMessage("そのメールアドレスは既に登録されています。")
                                                    }else{
                                                        await Authenticate(mail, code, ()=>{setStep(3); setAuthenticateErrorMessage(null)}, ()=>{
                                                            setAuthenticateErrorMessage("認証コードが正しくありません。")
                                                        })
                                                    }
                                                }})
                                            }}
                                            width='100%' 
                                            fontSize='14px' 
                                            height="4.5em"
                                            borderRadius='10px'
                                            boxShadow="none"
                                            text='認証する'/>
                                    </Stack>
                                </Stack>
                            }

                            {step === 3 &&
                                <Stack mt={2.5} spacing={isMobile ? 0:2} alignItems={"center"} width={"100%"}>

                                    {/* 区分 */}
                                    {/* ECサイトでは一般ユーザーのみを許可する */}
                                    {/* {radioForm(isMobile, "区分*", type, setType)} */}

                                    <Stack pt={3} alignItems={"center"} width={"100%"} spacing={2}>

                                        {/* 発案者(一般ユーザー) */}
                                        {type == 0 &&
                                            <>
                                                {textForm(isMobile, "ユーザーネーム"+(provisional_user == null ? "*" : ""), "ユーザーネームを入力", user_name, setUserName)}
                                                {user_name.trim() === "" && textForm(isMobile, "", "", user_name, setUserName, (user_name:string) => {return user_name.trim() === ""}, true, "ユーザーネームを入力してください。")}
                                                        
                                                {/* メールアドレス登録の場合 */}
                                                {provisional_user?.user_id === undefined &&
                                                    <>
                                                        <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                            {textForm(isMobile, "メールアドレス"+(provisional_user == null ? "*" : ""), "メールアドレスを入力", mail, setMail, (mail) => {return mailErrorCheck(mail)}, false)}
                                                            {mailErrorCheck(mail) === false && mail !== "" && textForm(isMobile, "", "", mail, setMail, (mail:string) => {return mailErrorCheck(mail) === false}, true, mail_error_message ??  "")}
                                                        </Stack>
                                                        <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                            {textForm(isMobile, "パスワード"+(provisional_user == null ? "*" : ""), "半角英数6文字以上", pass, setPass, (pass) => {return passDuplicateErrorCheck(pass, mailsAndPasswords) || passErrorCheck(pass)}, false)}
                                                            {passDuplicateErrorCheck(pass, mailsAndPasswords) && pass !== "" && textForm(isMobile, "", "", pass, setPass, (mail:string) => {return passDuplicateErrorCheck(pass, mailsAndPasswords)}, true, "このパスワードはすでに使用されています")}
                                                            {passErrorCheck(pass) && pass !== "" && textForm(isMobile, "", "", pass, setPass, passErrorCheck, true, "【半角英数6文字以上】例. xasyq1234")}
                                                        </Stack>
                                                    </>
                                                }
                                            </>
                                        }

                                        {/* 製作者(個人) */}
                                        {type == 1 &&
                                            <> 
                                                {textForm(isMobile, "ユーザー名*", "ユーザー名を入力", user_name, setUserName)}
                                                {user_name.trim() === "" && textForm(isMobile, "", "", user_name, setUserName, (user_name:string) => {return user_name.trim() === ""}, true, "ユーザーネームを入力してください。")}
                                                
                                                {provisional_user == null &&
                                                    <>
                                                        <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                            <Stack width={"100%"}>
                                                                {textForm(isMobile, "メールアドレス"+(provisional_user == null ? "*" : ""), "メールアドレスを入力", mail, setMail, (mail) => {return mailErrorCheck(mail)}, false)}
                                                                {mail_error_message && <Typography width={"100%"} textAlign={"left"} color={"red"}>{mail_error_message}</Typography>}
                                                            </Stack>
                                                            {mailErrorCheck(mail) && mail !== "" && textForm(isMobile, "", "", mail, setMail, (mail:string) => {return mailErrorCheck(mail)}, true, mail_error_message ?? "")}
                                                        </Stack>
                                                        <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                            {textForm(isMobile, "パスワード"+(provisional_user == null ? "*" : ""), "半角英数6文字以上", pass, setPass, (pass) => {return passDuplicateErrorCheck(pass, mailsAndPasswords) || passErrorCheck(pass)}, false)}
                                                            {passDuplicateErrorCheck(pass, mailsAndPasswords) && pass !== "" && textForm(isMobile, "", "", pass, setPass, (mail:string) => {return passDuplicateErrorCheck(pass, mailsAndPasswords)}, true, "このパスワードはすでに使用されています")}
                                                            {passErrorCheck(pass) && pass !== "" && textForm(isMobile, "", "", pass, setPass, passErrorCheck, true, "【半角英数6文字以上】例. xasyq1234")}
                                                        </Stack>
                                                    </>
                                                }
                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    {textForm(isMobile, "電話番号*", "0000-0000-0000", tel, setTel, telErrorCheck, false)}
                                                    {telErrorCheck(tel) && tel !== "" && textForm(isMobile, "", "", tel, setTel, telErrorCheck, true, "【正しい形式で入力してください】例. 0000-0000-0000")}
                                                </Stack>

                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    {textForm(isMobile, "郵便番号*", "000-0000", zipcode, setZipcode, zipcodeErrorCheck, false, "", 8)}
                                                    {zipcodeErrorCheck(zipcode) && zipcode !== "" && textForm(isMobile, "", "", zipcode, setZipcode, zipcodeErrorCheck, true, "【正しい形式で入力してください】例. 000-0000")}
                                                </Stack>

                                                {textForm(isMobile, "住所*", "住所を入力", address, setAddress)}
                                                {categoryForm(categories, setCategories)}
                                                {textForm(isMobile, "自己紹介", "自己紹介を300文字以内で入力", description, setDescription, (e:string)=>{return false}, false, "", 300, 4, true)}
                                            </>
                                        }

                                        {/* 製作者(法人) */}
                                        {type === 2 &&
                                            <>
                                                {textForm(isMobile, "企業名"+(provisional_user == null ? "*" : ""), "企業名を入力", user_name, setUserName)}
                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    <Stack width={"100%"}>
                                                        {textForm(isMobile, "メールアドレス"+(provisional_user == null ? "*" : ""), "メールアドレスを入力", mail, setMail, (mail) => {return mailErrorCheck(mail)}, false)}
                                                        {mail_error_message && <Typography width={"100%"} textAlign={"left"} color={"red"}>{mail_error_message}</Typography>}
                                                    </Stack>
                                                    {mailErrorCheck(mail) && mail !== "" && textForm(isMobile, "", "", mail, setMail, (mail:string) => {return mailErrorCheck(mail)}, true, mail_error_message ??  "")}
                                                </Stack>
                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    {textForm(isMobile, "パスワード"+(provisional_user == null ? "*" : ""), "半角英数6文字以上", pass, setPass, (pass) => {return passDuplicateErrorCheck(pass, mailsAndPasswords) || passErrorCheck(pass)}, false)}
                                                    {passDuplicateErrorCheck(pass, mailsAndPasswords) && pass !== "" && textForm(isMobile, "", "", pass, setPass, (mail:string) => {return passDuplicateErrorCheck(pass, mailsAndPasswords)}, true, "このパスワードはすでに使用されています")}
                                                    {passErrorCheck(pass) && pass !== "" && textForm(isMobile, "", "", pass, setPass, passErrorCheck, true, "【半角英数6文字以上】例. xasyq1234")}
                                                </Stack>
                                                {textForm(isMobile, "担当者名*", "担当者名を入力", manager_name, setManagerName)}
                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    {textForm(isMobile, "電話番号*", "0000-0000-0000", tel, setTel, telErrorCheck, false)}
                                                    {testForm(isMobile, "電話番号*", "0000-0000-0000", tel, setTel, telErrorCheck, false)}
                                                    {telErrorCheck(tel) && tel !== "" && textForm(isMobile, "", "", tel, setTel, telErrorCheck, true, "【正しい形式で入力してください】例. 0000-0000-0000")}
                                                </Stack>
                                                <Stack alignItems={"center"} width={"100%"} spacing={0.5}>
                                                    {textForm(isMobile, "郵便番号*", "000-0000", zipcode, setZipcode, zipcodeErrorCheck, false, "", 8)}
                                                    {zipcodeErrorCheck(zipcode) && zipcode !== "" && textForm(isMobile, "", "", zipcode, setZipcode, zipcodeErrorCheck, true, "【正しい形式で入力してください】例. 000-0000")}
                                                </Stack>
                                                {textForm(isMobile, "住所*", "住所を入力", address, setAddress)}
                                                {categoryForm(categories, setCategories)}
                                                {textForm(isMobile, "企業説明", "企業説明を300文字以内で入力", description, setDescription, (e:string)=>{return false}, false, "", 300, 4, true)}
                                            </>
                                        }
                                    </Stack>
                                    
                                    <Stack pt={3} direction={"column"} width={"100%"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                                        <MoreButton 
                                            disabled={registerStatus === 100 || user_name?.trim() === "" || 
                                                (provisional_user?.user_id === undefined && (pass?.trim() === "" || passErrorCheck(pass) || mail?.trim() === "" || mailErrorCheck(mail) === false)) || 
                                                (provisional_user?.user_id !== undefined && (user_name?.trim() === ""))}
                                            onClick={async ()=> {
                                                // SNSログインの場合は user_name が入っていればとりあえずよし(一般ユーザーしか実装できていない)
                                                if(provisional_user?.user_id !== undefined  && user_name?.trim() !== "" ){
                                                    await putUserMutation.mutate({user_id: registerID, newValues:{type:String(type), user_name:user_name, tel:tel, zip_code:zipcode, description:description, manager_name:manager_name, provisional:"0"}},
                                                                                 {onSuccess:async()=>{await presetLoginMutation.mutate(); await setStep(4)}, onError:()=>{setRegisterStatus(100)}})
                                                }else if(user_name?.trim() == "" || ![0,1,2].includes(type) || mail?.trim() == "" || pass?.trim() == ""){
                                                    return;
                                                }else{
                                                    // 仮登録済み(SNS認証済みの場合は)
                                                    if(provisional_user?.provisional === true && provisional_user?.user_id !== undefined){
                                                        await putUserMutation.mutate({ user_id: registerID, newValues:{type:String(type), user_name:user_name, mail:mail, password:pass, tel:tel, zip_code:zipcode, description:description, manager_name:manager_name, provisional:"0"}},
                                                                                     {onSuccess:async()=>{await presetLoginMutation.mutate(); await setStep(4)}, onError:()=>{setRegisterStatus(100)}})
                                                    }else{
                                                        // 不正なメールアドレス
                                                        if(mailErrorCheck(mail) === false){
                                                            setMailErrorMessage("メールアドレスの形式が正しくありません。例.example@domain.com")
                                                        }else{
                                                            // 既に登録されているメールアドレスなら弾く
                                                            await getUserMutation.mutate({type: "simple", user_id:null, query: "mail="+mail+"&count=true"}, {onSuccess: async (result:any)=>{
                                                                if(Number(result.count) > 0){
                                                                    setRegisterStatus(100)
                                                                }else{
                                                                    await putUserMutation.mutate({user_id: null, newValues:{type:String(type), user_name:user_name, mail:mail, password:pass, tel:tel, zipcode:zipcode, description:description, manager_name:manager_name, provisional:"0"}},
                                                                    {onSuccess:()=>{login_mutation.mutate(); setStep(4)}, onError:()=>{setRegisterStatus(100)}})
                                                                }
                                                            }})
                                                         }
                                                    }
                                                }
                                            }}
                                            width='100%' 
                                            height="4.5em"
                                            fontSize='14px'
                                            borderRadius='10px'
                                            boxShadow="none"
                                            text={registerStatus===100 ? "既に登録されています" : '登録する'}/>
                                        {registerStatus === 100 &&
                                            <Typography textAlign="right" component="div" sx={{width:"100%", margin:"5px auto 2px auto", letterSpacing: '0px'}}>
                                                <Box onClick={()=>{if(handleOpenLogin) handleOpenLogin()}} sx={{ cursor:"pointer", ":hover":{textDecoration:"underline"}, color: "dodgerblue", fontSize:12, fontWeight: 'bold',  }}>
                                                    ログインする
                                                </Box>
                                            </Typography>
                                        }
                                    </Stack>
                                    
                                </Stack>
                            }

                            {step >= 4 &&
                                <Stack mt={3} spacing={2} alignItems={"center"} justifyContent={"center"} width={"100%"}>
                                    <Typography sx={{color: "#424242", fontSize:isMobile ? 20 : 24, fontWeight: 'bold', }}>
                                        会員登録が完了しました！
                                    </Typography>
                                    <Divider sx={{width:"40%", backgroundColor:"#383E86"}}/>
                                    <Stack alignItems={"center"}>
                                        <Typography sx={{color: "#424242", fontSize:isMobile ? 12 : 15, fontWeight: 'bold', }}>
                                            RE-IDEAへのご登録ありがとうございます！
                                        </Typography>
                                        <Typography sx={{color: "#424242", fontSize:isMobile ? 12 : 15, fontWeight: 'bold', }}>
                                            あなたは<span style={{ fontSize:isMobile ? 14 : 18, color:"#383E86" }}>{type == 0 ? "「IDEA発案者」" : type == 1 ? "「製作者(個人)」" : "「製作者(法人)」" }</span>です。IDEAを提案して商品化を目指しましょう！
                                        </Typography>
                                    </Stack>


                                    <Stack width={"100%"} alignItems={"center"} spacing={1}>
                                        <MoreButton 
                                            onClick={()=>{
                                                handleClose()
                                                window.location.href="/search?mode=idea"
                                            }} 
                                            width='70%' 
                                            fontSize={isMobile ? "12px" : "14px"}
                                            height={isMobile ? "3.5em" : '3.5em'}
                                            borderRadius={isMobile ? "10px" : '10px'}
                                            backgroundColor='#383E86'
                                            boxShadow="none"
                                            text='IDEA一覧を見てみる'/>

                                        <MoreButton 
                                            onClick={()=>{
                                                openIdeaPostPopup();
                                            }} 
                                            width='70%' 
                                            fontSize={isMobile ? "12px" : "14px"}
                                            height={isMobile ? "3.5em" : '3.5em'}
                                            borderRadius={isMobile ? "10px" : '10px'}
                                            boxShadow="none"
                                            backgroundColor='#383E86'
                                            text='IDEAを投稿してみる'/>
                                    </Stack>                       
                                </Stack>
                            }
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
        <PopupRegisterError open={showRegisterErrorPopup} handleClose={()=>{setShowRegisterErrorPopup(false)}} isMobile={isMobile} />
    </>
  );
};

export default PopupRegisterForm;